import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Img from "gatsby-image"
import ArrowRight from "../../../images/arrow-right.svg"

export default function SmoothieAcaiBerryBlastCup(props) {
  const images = useStaticQuery(graphql`
    query cupImageSmoothieAcaiBerryBlastCup {
      cupImageLarge: file(
        relativePath: { eq: "front-page-flavors/smoothie-acai-berry-blast.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 320) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      cupImageMedium: file(
        relativePath: { eq: "front-page-flavors/smoothie-acai-berry-blast.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 260) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      cupImageSmall: file(
        relativePath: { eq: "front-page-flavors/smoothie-acai-berry-blast.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 180) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const cupSources = [
    images.cupImageLarge.childImageSharp.fluid,
    {
      ...images.cupImageMedium.childImageSharp.fluid,
      media: `(max-width: 768px)`,
    },
    {
      ...images.cupImageSmall.childImageSharp.fluid,
      media: `(max-width: 540px)`,
    },
  ]

  return (
    <>
      <Img fluid={cupSources} />
      <div className="flavor-label">
        <h2>Acai Berry Blast Smoothie</h2>
        <Link to="/flavors" state={{ flavor: "smoothieAcaiBerryBlast" }}>
          Check out the details
          <ArrowRight />
        </Link>
      </div>
    </>
  )
}

const Svg = styled.svg`
  .active-bg-1 {
    transition: all 0.2s ease-in-out;
    opacity: ${props => (props.active ? 1 : 0)};
  }

  .border-1 {
    transition: all 0.2s ease-in-out;
    fill: ${props => (props.active ? "#862886" : props.theme.color.primary)};
  }
  .border-2 {
    transition: all 0.2s ease-in-out;
    fill: ${props => (props.active ? "#185C2E" : props.theme.color.primary)};
  }
`
export function SmoothieAcaiBerryBlastThumb(props) {
  const { active } = props

  return (
    <>
      {/* Chocolate Bar */}
      <Svg
        version="1.1"
        viewBox="0 0 146 146"
        xmlns="http://www.w3.org/2000/svg"
        active={active}
      >
        <path
          className="active-bg-1"
          d="m48.175 65.584c-8.068 0-14.608 6.5404-14.608 14.608s6.5404 14.608 14.608 14.608 14.608-6.5404 14.608-14.608c-0.009423-8.0641-6.5443-14.599-14.608-14.608z"
          fill="#BF70AE"
        />
        <path
          className="active-bg-1"
          d="m91.388 69.325c-11.881 0-21.513 9.6315-21.513 21.513 0 11.881 9.6313 21.513 21.512 21.513 11.881 0 21.513-9.6312 21.513-21.512-0.013282-11.876-9.6371-21.499-21.513-21.513z"
          fill="#BF70AE"
        />
        <path
          className="active-bg-1"
          d="m85.554 49.967c-5.9863 0.8877-13.301 5.9375-14.383 14.566 3.4977-0.22594 6.7916-1.7245 9.26-4.2129 2.7573-2.8242 4.5504-6.4483 5.1228-10.354z"
          fill="#72BC81"
        />
        <path
          className="active-bg-1"
          d="m53.03 33.512c-1.4418 6.4457-0.67629 13.189 2.1737 19.148 2.5976 5.3182 7.1486 9.4279 12.703 11.472 3.5039-14.414-5.834-26.497-14.877-30.619z"
          fill="#72BC81"
        />
        <path
          className="border-2"
          d="m52.571 30.086c9.6625 3.4862 17.898 13.603 18.941 25.457 3.9411-5.6124 10.488-8.6203 15.624-8.7156 0.40113-0.010403 0.78913 0.14339 1.0742 0.4258 0.2871 0.28011 0.45025 0.6634 0.4531 1.0645-0.19058 5.3121-2.3749 10.358-6.1181 14.132-3.2752 3.2563-7.6911 5.096-12.298 5.1363l-0.72153-0.0035069c-0.096284-0.0012871-0.19147-0.011822-0.28439-0.03109-0.10118 0.02179-0.20666 0.033173-0.31421 0.033091-0.15718 0-0.31339-0.02468-0.4629-0.0732-6.9471-2.1231-12.714-7.0114-15.947-13.517-4.0088-8.0645-3.8281-17.357-1.8721-22.989 0.13181-0.3785 0.40949-0.68857 0.77122-0.86117 0.36172-0.1726 0.77745-0.19338 1.1546-0.05773zm32.983 19.881c-5.9863 0.8877-13.301 5.9375-14.383 14.566 3.4977-0.22594 6.7916-1.7245 9.26-4.2129 2.7573-2.8242 4.5504-6.4483 5.1228-10.354zm-32.524-16.455c-1.4418 6.4457-0.67629 13.189 2.1737 19.148 2.5976 5.3182 7.1486 9.4279 12.703 11.472 3.5039-14.414-5.834-26.497-14.877-30.619z"
          fill="#185C2E"
        />
        <path
          className="border-1"
          d="m100.77 68.191c9.1598 3.7942 15.132 12.733 15.132 22.647-0.015266 13.532-10.981 24.497-24.513 24.512-9.9145 0-18.853-5.9724-22.647-15.132-3.7941-9.1598-1.6968-19.703 5.3139-26.714s17.554-9.1077 26.714-5.3134zm-9.3809 1.134c-11.881 0-21.513 9.6315-21.513 21.513 0 11.881 9.6313 21.513 21.512 21.513 11.881 0 21.513-9.6312 21.513-21.512-0.013282-11.876-9.6371-21.499-21.513-21.513zm-43.213-6.7408c9.7249 0 17.608 7.8835 17.608 17.608-0.011242 9.7202-7.8882 17.597-17.608 17.608-9.7249 0-17.608-7.8835-17.608-17.608s7.8835-17.608 17.608-17.608zm0 3c-8.068 0-14.608 6.5404-14.608 14.608s6.5404 14.608 14.608 14.608 14.608-6.5404 14.608-14.608c-0.009423-8.0641-6.5443-14.599-14.608-14.608z"
          fill="#862886"
        />
        <path
          className="border-1"
          d="m92.539 91.7 7.82 7.8193c0.42886 0.42899 0.55713 1.0741 0.32502 1.6345s-0.77892 0.92589-1.3855 0.92602c-0.3976 6.53e-4 -0.77927-0.15752-1.0602-0.4393l-7.82-7.8193c-0.56723-0.58869-0.55861-1.5232 0.019385-2.1013 0.57799-0.57813 1.5125-0.58697 2.1013-0.019881zm0 0 7.82 7.8193c0.42886 0.42899 0.55713 1.0741 0.32502 1.6345s-0.77892 0.92589-1.3855 0.92602c-0.3976 6.53e-4 -0.77927-0.15752-1.0602-0.4393l-7.82-7.8193c-0.56723-0.58869-0.55861-1.5232 0.019385-2.1013 0.57799-0.57813 1.5125-0.58697 2.1013-0.019881zm7.4334 0.38728c0.57982 0.57983 0.58658 1.5178 0.015177 2.1059l-7.0762 7.0752c-0.2811 0.28189-0.66301 0.44004-1.0611 0.4394-0.6065-3.36e-4 -1.1531-0.36587-1.3851-0.92625s-0.10368-1.2053 0.32511-1.6343l7.0762-7.0752c0.58812-0.57141 1.5261-0.56465 2.1059 0.015177zm-56.578-18.797 5.6836 5.6836c0.42886 0.42899 0.55713 1.0741 0.32502 1.6345s-0.77892 0.92589-1.3855 0.92602c-0.398 3.547e-4 -0.77967-0.15782-1.0606-0.4396l-5.6836-5.6836c-0.56843-0.5886-0.56026-1.5242 0.018371-2.1027 0.57863-0.57857 1.5142-0.58666 2.1027-0.018173zm0 0 5.6836 5.6836c0.42886 0.42899 0.55713 1.0741 0.32502 1.6345s-0.77892 0.92589-1.3855 0.92602c-0.398 3.547e-4 -0.77967-0.15782-1.0606-0.4396l-5.6836-5.6836c-0.56843-0.5886-0.56026-1.5242 0.018371-2.1027 0.57863-0.57857 1.5142-0.58666 2.1027-0.018173zm5.9038-0.22032c0.57983 0.57983 0.58658 1.5178 0.015177 2.1059l-6.1544 6.1544c-0.28097 0.28171-0.66263 0.43981-1.0605 0.4393-0.60659-1.319e-4 -1.1534-0.36559-1.3855-0.92602s-0.10384-1.2055 0.32502-1.6345l6.1543-6.1543c0.58812-0.57141 1.5261-0.56465 2.1059 0.015177z"
          fill="#862886"
        />
      </Svg>
    </>
  )
}
